import {
  SkipPaymentOption,
  PaymentOption,
  descriptonT,
  PaymentOptionContentEntry,
} from 'lib/types/payment';
import { PromotionObject } from '../../types/promotion';

export const getDescriptionsWithDiscounts = (
  isGwat: boolean,
  descriptions: PaymentOptionContentEntry[] = [],
  formatter?: Intl.NumberFormat,
  discount?: number,
  vouchers: PromotionObject[] = []
) => {
  const result: descriptonT[] = [];
  descriptions?.forEach((item) => {
    const data: descriptonT = {
      text: item.content,
      isBold: false,
    };

    if (item.popup) {
      data.popup = {
        content: item.popup.content,
      };
    }

    result.push(data);
  });
  const voucherMessages = vouchers.map((voucher) => voucher.message);
  voucherMessages?.forEach((item) => {
    const promo = {
      text: item,
      isBold: true,
    };
    if (isGwat) {
      result.unshift(promo);
    } else {
      result.push(promo);
    }
  });

  if (!discount) return result;

  const formattedDiscount = formatter ? formatter.format(discount) : discount;

  const promo = {
    text: `${formattedDiscount} extra trip discount`,
    isBold: true,
  };
  if (isGwat) {
    result.unshift(promo);
  } else {
    result.push(promo);
  }

  return result;
};

export const getSkipPaymentOption = (
  option: PaymentOption
): SkipPaymentOption => {
  if (option === 'paid-full') return 'trip_in_full';
  return 'deposit';
};
